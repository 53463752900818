import { IngredientsIcon, ProjectsIcon, UserIcon, UsersIcon } from "components/common/Icons";
import * as Page from "components/pages";
import { Path, Routes, SidebarRoute } from "components/routes/routes.types";

export const routes = {
    ingredients: {
        path: "/ingredients",
        label: "Ingredients",
        Page: Page.IngredientsPage,
        Icon: IngredientsIcon,
    },
    ingredientDetail: {
        path: "/ingredients/:ingredientId",
        label: "Ingredient Detail",
        Page: Page.IngredientDetailPage,
    },
    ingredientCreate: {
        path: "/ingredients/create",
        label: "Create Ingredient",
        Page: Page.CreateIngredientPage,
    },
    ingredientEdit: {
        path: "/ingredients/:ingredientId/edit",
        label: "Edit Ingredient",
        Page: Page.EditIngredientPage,
    },
    ingredientCopy: {
        path: "/ingredients/:ingredientId/copy",
        label: "Copy Ingredient",
        Page: Page.CopyIngredientPage,
    },
    ingredientHistory: {
        path: "/ingredients/:ingredientId/history",
        label: "Ingredient History",
        Page: Page.IngredientHistoryPage,
    },
    projects: {
        path: "/projects",
        label: "Projects",
        Page: Page.ProjectsPage,
        Icon: ProjectsIcon,
    },
    projectDetail: {
        path: "/projects/:projectId",
        label: "Project Detail",
        Page: Page.ProjectDetailPage,
    },
    projectCreate: {
        path: "/projects/create",
        label: "Create Project",
        Page: Page.CreateProjectPage,
    },
    projectEdit: {
        path: "/projects/:projectId/edit",
        label: "Edit Project",
        Page: Page.EditProjectPage,
    },
    projectProductDetail: {
        path: "/projects/:projectId/products/:productId",
        label: "Product Detail",
        Page: Page.ProductDetailPage,
    },
    projectProductCreate: {
        path: "/projects/:projectId/products/create",
        label: "Create Product",
        Page: Page.CreateProductPage,
    },
    projectProductEdit: {
        path: "/projects/:projectId/products/:productId/edit",
        label: "Edit Product",
        Page: Page.EditProductPage,
    },
    projectProductHistory: {
        path: "/projects/:projectId/products/:productId/history",
        label: "Product History",
        Page: Page.ProductHistoryPage,
    },
    projectIngredients: {
        path: "/projects/:projectId/ingredients",
        label: "Project Ingredients",
        Page: Page.ProjectIngredientsPage,
    },
    projectIngredientDetail: {
        path: "/projects/:projectId/ingredients/:ingredientId",
        label: "Ingredient Detail",
        Page: Page.IngredientDetailPage,
    },
    projectIngredientCreate: {
        path: "/projects/:projectId/ingredients/create",
        label: "Create Ingredient",
        Page: Page.CreateIngredientPage,
    },
    projectIngredientEdit: {
        path: "/projects/:projectId/ingredients/:ingredientId/edit",
        label: "Edit Ingredient",
        Page: Page.EditIngredientPage,
    },
    projectIngredientCopy: {
        path: "/projects/:projectId/ingredients/:ingredientId/copy",
        label: "Copy Ingredient",
        Page: Page.CopyIngredientPage,
    },
    projectIngredientHistory: {
        path: "/projects/:projectId/ingredients/:ingredientId/history",
        label: "Ingredient History",
        Page: Page.IngredientHistoryPage,
    },
    projectUsers: {
        path: "/projects/:projectId/users",
        label: "Project Users",
        Page: Page.ProjectUsersPage,
    },
    projectUsersEdit: {
        path: "/projects/:projectId/users/edit",
        label: "Edit project users",
        Page: Page.ProjectUsersEditPage,
    },
    userProfile: {
        path: "/user-profile",
        label: "User Profile",
        Page: Page.UserProfilePage,
        Icon: UserIcon,
    },
    users: {
        path: "/users",
        label: "Users",
        Page: Page.UsersPage,
        Icon: UsersIcon,
    },
} satisfies Routes;

const sidebarRouteNames = ["projects", "ingredients", "users"] satisfies (keyof typeof routes)[];
export const sidebarRoutes: SidebarRoute[] = sidebarRouteNames.map((name) => routes[name]);

export const allRoutePaths: Path[] = Object.values(routes).map((route) => route.path);

export const importPaths = {
    importProduct: "/import/product",
};
